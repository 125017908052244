import { Link } from "react-router-dom";
import NoImage from "../../assets/img/no-image.png"
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EditCustomerModal from "./EditCustomerModal";
import { useState } from "react";
import { getCustomerById } from "../../api/customer";
import { useCheckPermissions } from "../../hooks/useCheckPermissions";
import { CustomerObject } from "../../utils/data-types";
import DeleteCustomerModal from "./DeleteCustomerModal";

export interface CustomerCardProp {
    id: string;
    customer: string;
    logoUrl: string | null; // made logoUrl Null to reuse interface for delete customer
}

export default function CustomerCard({ id, customer, logoUrl }: CustomerCardProp) {
    const [editCustomerModalShow, setEditCustomerModalShow] = useState<boolean>(false);
    const [deleteCustomerModalShow, setDeleteCustomerModalShow] = useState<boolean>(false);
    const [deleteCustomerObject, setDeleteCustomerObject] = useState<CustomerCardProp>({
        customer: "",
        id: "",
        logoUrl: null
    });
    const [modalData, setModalData] = useState(null);
    const [loading, setLoading] = useState(false);
    const { data: userPermissionData } = useCheckPermissions();

    // Users permissions
    const customersAppProps: CustomerObject = userPermissionData?.Customers

    // Super User permissions
    const superUserCustomerAppProps: CustomerObject =
        userPermissionData && userPermissionData.SuperUserPermissions
            ? userPermissionData.SuperUserPermissions
            : []

    // Check if passed values is in any of the Super User Permissions array
    // Only super user can delete customers
    let canDeleteCustomer = false;
    if (superUserCustomerAppProps.includes("customer_mgmt")) {
        canDeleteCustomer = true;
    }

    // Check if passed values is in any of the User Permissions array
    let canEditCustomer = false;
    for (const customerProps of Object.values(customersAppProps || {})) {
        if (customerProps.Id === id && customerProps.UserPermissions.includes("customer_mgmt")) {
            canEditCustomer = true;
            break;
        }
    }

    const handleEditClick = async () => {
        try {
            setLoading(true)
            setEditCustomerModalShow(true)
            const response = await getCustomerById(id);
            setModalData(response);
            setLoading(false)
        } catch (error) {
            console.error("Error:", error);
        }
    };

    return (
        <>
            <EditCustomerModal
                show={editCustomerModalShow}
                onHide={() => { setEditCustomerModalShow(false) }}
                data={modalData}
                loading={loading}
            />

            <DeleteCustomerModal
                showDeleteModal={deleteCustomerModalShow}
                onHideDeleteModal={() => { setDeleteCustomerModalShow(false) }}
                customerObject={deleteCustomerObject}

            />


            <div key={id} className="col">
                <div className="card h-100">
                    <div className="card-body d-flex align-items-center justify-content-center">
                        <Link to={`/customers/${customer}/${id}`} className="text-decoration-none text-body">
                            <img src={logoUrl || NoImage} className="img-fluid" alt={customer} />
                        </Link>
                    </div>
                    <div className="d-flex justify-content-between align-items-center card-footer">
                        <small className="fw-500">{customer}</small>
                        {canDeleteCustomer
                            ?
                            <div className="d-flex justify-content-center align-items-center ">
                                <button className="border-bg-none" onClick={handleEditClick}>
                                    <FontAwesomeIcon height={16} width={16} icon={faEdit} />
                                </button>
                                <button className="border-bg-none" style={{ paddingBottom: "2px" }} onClick={() => { setDeleteCustomerObject({ customer: customer, id: id, logoUrl: null }); setDeleteCustomerModalShow(true) }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash3" viewBox="0 0 16 16">
                                        <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                                    </svg>
                                </button>
                            </div>
                            :
                            canEditCustomer
                                ?
                                <div className="d-flex justify-content-center align-items-center ">
                                    <button className="border-bg-none" onClick={handleEditClick}>
                                        <FontAwesomeIcon height={16} width={16} icon={faEdit} />
                                    </button>
                                </div>
                                :
                                null
                        }
                    </div>
                </div>
            </div>
        </>
    );
}