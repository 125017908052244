// reusable constants
export const RUN_STARTED: string = "RunStarted";
export const RUN_CANCELLED: string = "RunCancelled";
export const RUN_FINISHED: string = "RunFinished";

export interface QueryResponse {
    isLoading?: boolean,
    isError?: boolean,
    data: any | null,
    error?: Error | null,
    refetch?: (options?: { throwOnError: boolean, cancelRefetch: boolean }) => Promise<any>,
    refetchInterval?: number,
    isFetching?: boolean
}

export interface PlaywrightTestTableProps {
    Name: string,
    RunDateTimeUtc: string,
    RunDuration: string,
    TestCount: number,
    FailCount: number,
    TimeoutCount: number,
    FlakyCount: number,
    IndexName: string,
    LogName: string,
    RepoTag?: string,
    AgentName?: string,
    ExecutingApp?: string,
    ExecutingVM?: string,
    index: string
}

export interface CustomerInputs {
    DisplayName: string,
    Abbreviation: string,
    DefaultBranch: string,
    RepoAccessKey: string | null | undefined,
    RepoUrl: string,
    RepoSSHUrl: string
    RepoSSHPrivateKey: string | null | undefined,
    Id?: string,
    CanUseMSPlaywrightService: boolean | null
}

export interface UploadImageProps {
    customerId?: string,
    imageData: File
}

export type AnyObject = Record<string, string | null | undefined | boolean>;

export interface updateUserProps {
    userId: string | undefined,
    roleAssignmentData: { [key: string]: any }
}

export interface userTableProps {
    Email: string,
    FirstName: string,
    LastName: string,
    Id: string,
    CustomerRoles: {
        [key: string]: {
            Abbreviation: string;
            DisplayName: string;
            Roles: Array<string>;
        };
    };
}

export interface InviteUser {
    FirstName: string,
    LastName: string,
    Email: string
}

export type CustomerObject = {
    [key: string]: any
}