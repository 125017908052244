import { useState } from "react";
import ToggleableButton from "../../components/ToggleableButton";
import PageTemplate from "../../components/PageTemplate";
import UserTable from "./UserTable";
import CreateUserModal from "./CreateUserModal";

export default function Users() {
    const [createUserModalShow, setCreateUserModalShow] = useState<boolean>(false);

    const createUserBtn = <ToggleableButton buttonGroup={false} className={"btn btn-primary"} title={"Create User"} onClick={() => setCreateUserModalShow(true)} />

    return (
        <>
            <CreateUserModal
                show={createUserModalShow}
                onHide={() => { setCreateUserModalShow(false) }}
            />

            <PageTemplate title="Users" launchButton={createUserBtn}>
                <UserTable />
            </PageTemplate>
        </>
    );
}