import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { useForm, SubmitHandler } from "react-hook-form"
import { InviteUser } from "../../utils/data-types";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ThreeDots } from "react-loader-spinner";
import { inviteUser } from "../../api/users";

export default function CreateUserModal(props: any) {
    const { onHide } = props;
    const queryClient = useQueryClient();
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<InviteUser>()

    const createUser = useMutation({
        mutationFn: (inputData: InviteUser) => inviteUser(inputData),
        onSuccess: () => reset(),
        onSettled: async () => {
            await queryClient.invalidateQueries({ queryKey: ["Users"] })
        },
    });

    const OnSubmit: SubmitHandler<InviteUser> = async (inputData) => {
        try {
            await createUser.mutateAsync(inputData);
            onHide()
        } catch (error) {
            console.log("Error", error)
            onHide()
        }
    };

    const isValidEmail = (email: string) =>
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
            .test(
                email
            );

    const handleEmailValidation = (email: string) => {
        return isValidEmail(email);
    };

    return (
        <>
            <Modal
                {...props}
                className="create-customer-modal"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Container>
                    <Modal.Header closeButton className='border-bottom-0 ps-1 pe-1 pb-0'>
                        <Modal.Title className="fw-semibold" id="contained-modal-title-vcenter">
                            Create User
                        </Modal.Title>
                    </Modal.Header>
                </Container>
                <Container>
                    <Form noValidate onSubmit={handleSubmit(OnSubmit)}>
                        <Modal.Body className='ps-1 pe-1 pt-1 pb-0'>
                            <p>
                                Please fill out the following details below.
                            </p>
                            <Row>
                                <Form.Group as={Col} className="mb-3" controlId="validationCustom01">
                                    <Form.Label style={{ fontWeight: "500" }}>First Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        {...register("FirstName", { required: true })}
                                        className={
                                            errors.FirstName
                                                ? "is-invalid form-control"
                                                : "form-control"
                                        }
                                    />
                                </Form.Group>
                                <Form.Group as={Col} className="mb-3" controlId="validationCustom02">
                                    <Form.Label style={{ fontWeight: "500" }}>Last Name</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        {...register("LastName", { required: true })}
                                        className={
                                            errors.LastName
                                                ? "is-invalid form-control"
                                                : "form-control"
                                        }
                                    />
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group as={Col} className="mb-3" controlId="validationCustom03">
                                    <Form.Label style={{ fontWeight: "500" }}>Email</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        {...register("Email", {
                                            required: true,
                                            validate: handleEmailValidation
                                        })}
                                        className={
                                            errors.Email?.type === "required" || errors.Email?.type === "validate"
                                                ? "is-invalid form-control"
                                                : "form-control"
                                        }

                                    />
                                    {errors.Email?.type === "validate" && (
                                        <span className="text-danger" role="alert">Invalid email address</span>
                                    )}

                                </Form.Group>
                                <Col />
                            </Row>

                        </Modal.Body >
                        <Modal.Footer className="border-top-0">
                            <Button variant="outline-primary" onClick={onHide}>Cancel</Button>
                            <Button variant="primary" type="submit">
                                {createUser.isPending
                                    ?
                                    < ThreeDots color="white" height="24" width="118" />
                                    :
                                    "Create User"}
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Container >
            </Modal >
        </>
    )
}