
import { Modal, Button } from "react-bootstrap";
import { deleteCustomerById } from "../../api/customer";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CustomerCardProp } from "./CustomerCard";

interface DeleteCustomerProps {
    onHideDeleteModal: () => void;
    showDeleteModal: boolean;
    customerObject: CustomerCardProp
}

export default function DeleteCustomerModal(props: DeleteCustomerProps) {
    const { onHideDeleteModal, customerObject, showDeleteModal } = props
    const queryClient = useQueryClient();

    const deleteCustomerMutation = useMutation({
        mutationFn: (customerId: string) => deleteCustomerById(customerId),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["customers"] })
        }
    });

    const handleDeleteCustomer = async () => {
        try {
            await deleteCustomerMutation.mutateAsync(customerObject.id)
            onHideDeleteModal();
        } catch (error) {
            console.error(error)
        }
    };

    return (

        <>
            <Modal
                show={showDeleteModal}
                onHide={onHideDeleteModal}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton className='border-bottom-0 pb-2'>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Delete Customer
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='pt-0 pb-0'>
                    <div>
                        <div>Are you sure you want to delete the customer <span className="fw-semibold">"{customerObject.customer}" </span>?
                            This action is permanent and cannot be undone.</div>
                    </div>
                </Modal.Body>
                <Modal.Footer className='border-top-0'>
                    <Button variant="outline-primary" onClick={onHideDeleteModal}>Cancel</Button>
                    <Button className="danger" onClick={handleDeleteCustomer}>{deleteCustomerMutation.isPending ? "Deleting..." : "Delete Customer"}</Button>
                </Modal.Footer>
            </Modal >
        </>
    )
}