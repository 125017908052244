import { Modal, Button } from "react-bootstrap";
import { deleteUser } from "../../api/users";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export default function DeteleUserModal(props: any) {
    const { deleteUserInfo, onHide } = props
    const queryClient = useQueryClient();

    const deleteUserMutation = useMutation({
        mutationFn: (userId: string) => deleteUser(userId),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["Users"] })
        }
    });

    const handleDelete = async () => {
        try {
            await deleteUserMutation.mutateAsync(deleteUserInfo.Id)
            onHide();
        } catch (error) {
            console.error(error)
        }
    };

    return (
        <>
            <Modal
                {...props}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton className='border-bottom-0 pb-2'>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Delete User
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='pt-0 pb-0'>
                    <div>
                        <div>Are you sure you want to delete the user <span>"{deleteUserInfo?.FirstName} {deleteUserInfo?.LastName}"</span>?</div>
                        <div>This action is permanent and cannot be undone.</div>
                    </div>
                </Modal.Body>
                <Modal.Footer className='border-top-0'>
                    <Button variant="outline-primary" onClick={onHide}>Cancel</Button>
                    <Button className="danger" onClick={handleDelete}>{deleteUserMutation.isPending ? "Deleting..." : "Delete Record"}</Button>
                </Modal.Footer>
            </Modal >
        </>
    );
}