//
import { Link, useLocation, useParams } from "react-router-dom";
import { RemoveCharCapitalize } from "../utils/helpers";
import useBreadcrumbs, { BreadcrumbsRoute } from "use-react-router-breadcrumbs";

export default function Breadcrumbs() {
    const { customer } = useParams();
    const location = useLocation();
    const customerPath = location.pathname;

    const routes = [
        {
            path: `/${customerPath}`,
            breadcrumb: RemoveCharCapitalize(customer)
        },
        {
            path: "/:customer/:id",
            breadcrumb: null
        }

    ].filter(Boolean) as BreadcrumbsRoute[]

    const breadcrumbs = useBreadcrumbs(routes, { excludePaths: ["/"] });

    return (
        <>
            <div className="breadcrumbs">
                {breadcrumbs.map(({ breadcrumb, match }, index) => {
                    const isLastBreadcrumb = index === breadcrumbs.length - 1;
                    const isSoleBreadcrumb = breadcrumbs.length === 1;
                    return (
                        <div className="crumb" key={match.pathname}>
                            {!isLastBreadcrumb
                                ?
                                // is not last breadcrumb
                                <>

                                    <div className="material-symbols-outlined me-1" style={{ color: "#555A66" }} >
                                        house
                                    </div>
                                    <Link to={match.pathname || ""}>
                                        {breadcrumb}
                                        {index < breadcrumbs.length - 1 && <span className="separator">/</span>}
                                    </Link>
                                </>
                                :
                                isSoleBreadcrumb
                                    ?
                                    // first and only breadcrumb
                                    <>
                                        <div className="material-symbols-outlined me-1" style={{ color: "#2F6CDE" }}>
                                            house
                                        </div>
                                        <span className="crumb current">{breadcrumb}</span>
                                    </>
                                    :
                                    // last breadcrumb
                                    <div>
                                        <span className="crumb current">
                                            {breadcrumb}
                                            {index < breadcrumbs.length - 1 && <span className="separator">/</span>}
                                        </span>
                                    </div>
                            }
                        </div>
                    )
                })}
            </div>
        </>
    );
}