import axios from 'axios';
import { getApiAccessToken } from '../../features/Auth/authentication';

const axiosApiInstance = axios.create();

// Request interceptor for API calls
axiosApiInstance.interceptors.request.use(
    async config => {
        const accessToken = await getApiAccessToken();
        config.headers['Authorization'] =`Bearer ${accessToken}`
      return config;
    },
    error => {
      Promise.reject(error)
  });

export default axiosApiInstance;