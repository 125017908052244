import PageTemplate from "../../components/PageTemplate";

export default function NotFound() {
    return (
        <PageTemplate title="Not Found">
            <div className="not-found">
                <h1 className="text-danger">404 - Not found!</h1>
                <p>Sorry, the page you're looking for does not exist.</p>
            </div>
        </PageTemplate>
    )
}