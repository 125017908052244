
interface DataCounterProps {
    dataCount: number;
    typeOfData: string;
    totalCount?: number
}

export default function DataCounter({ dataCount, typeOfData, totalCount }: DataCounterProps) {
    return (
        <div className="data-count">
            <span>Showing </span>
            <span>
                {dataCount} {totalCount ? (`of ${totalCount}`) : (null)} {typeOfData}
            </span>
        </div>
    );
}