import { useMutation, useQueryClient } from '@tanstack/react-query';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { deleteRecord } from '../../api/history';
import { useParams } from 'react-router-dom';
import { invariant } from "../../utils/helpers";

interface DeleteRecordModalProps {
    hideDeleteRecordModal: () => void;
    deleteRecordName: string;
    showDeleteRecordModal: boolean;
}

export default function DeleteRecordModal(props: DeleteRecordModalProps) {
    const { deleteRecordName, hideDeleteRecordModal, showDeleteRecordModal } = props
    const { customer, id } = useParams();
    const queryClient = useQueryClient();

    // check if undefined
    invariant(customer);
    invariant(id);

    const deleteRecordMutation = useMutation({
        mutationFn: (record: string) => deleteRecord(id, record),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [customer, id, "History"] })
        }
    });

    const handleDelete = async () => {
        try {
            await deleteRecordMutation.mutateAsync(deleteRecordName)
            hideDeleteRecordModal();
        } catch (error) {
            console.error(error)
        }
    };

    return (
        <Modal
            show={showDeleteRecordModal}
            onHide={hideDeleteRecordModal}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton className='border-bottom-0'>
                <Modal.Title id="contained-modal-title-vcenter">
                    Delete Automated Test
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='pt-0 pb-0'>
                <p>
                    Are you sure you want to delete the automated test? This action is permanent and cannot be undone.
                </p>
            </Modal.Body>
            <Modal.Footer className='border-top-0'>
                <Button variant="outline-primary" onClick={hideDeleteRecordModal}>Cancel</Button>
                <Button className="danger" onClick={handleDelete}>{deleteRecordMutation.isPending ? "Deleting..." : "Delete Record"}</Button>
            </Modal.Footer>
        </Modal>
    );
}