import axiosApiInstance from "./config/axios-config"
import appSettings from "../appsettings.json"

export const getTestRunStatus = async (customerId: string, workId?: string): Promise<any> => {
    const response = await axiosApiInstance.get(`${appSettings.WebApi.BaseAddress}${appSettings.WebApi.AllCustomersEndpoint}/${customerId}/testruns${workId ? `/${workId}` : ""}`)
    return response.data

}

export const initiateTestRun = async (customerId: string, branch : string | null = null, testType = "Playwright"): Promise<any> => {
    const response = await axiosApiInstance.post(`${appSettings.WebApi.BaseAddress}${appSettings.WebApi.AllCustomersEndpoint}/${customerId}/testruns`,
        { "Branch": branch, "TestType": testType },
        {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    return response

}

export const cancelTestRun = async (customerId: string, workId: string): Promise<any> => {
    const response = await axiosApiInstance.delete(`${appSettings.WebApi.BaseAddress}${appSettings.WebApi.AllCustomersEndpoint}/${customerId}/testruns/${workId}`)
    return response.data

}