import { useAccount } from "@azure/msal-react";
import Logout from "./Logout";

export default function User() {
    const account = useAccount();
    let username = account?.idTokenClaims?.email as string || account?.idTokenClaims?.emails?.[0] || '';
    let usernameInitial = username.charAt(0).toUpperCase();

    return (
        <>
            <div className="btn-group">
                <div className="circle bg-light text-decoration-none" title={username} role="button" data-bs-toggle="dropdown" aria-expanded="false" >
                    <p className="circle-inner">{usernameInitial}</p>
                </div>
                <button className="dropdown-toggle logout d-flex align-items-center" data-bs-toggle="dropdown" aria-expanded="false" />
                <ul className="dropdown-menu bg-light p-0">
                    <li className="dropdown-item d-flex justify-content-center bg-light"><Logout /></li>
                </ul>
            </div>
        </>
    );
}