import { ChangeEvent, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { useForm, SubmitHandler } from "react-hook-form"
import { updateCustomerById } from "../../api/customer";
import { CustomerInputs, UploadImageProps } from "../../utils/data-types";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ThreeDots } from "react-loader-spinner";
import { uploadImage } from "../../api/images";
import UploadFile from "./UploadFile";

interface EditCustomerModalProps {
    onHide: () => void;
    show: boolean;
    data: any;
    loading: boolean;
}

export default function EditCustomerModal(props: EditCustomerModalProps) {

    const { onHide, data, loading, show } = props;
    const queryClient = useQueryClient();
    const [customer, setCustomer] = useState<CustomerInputs>({
        DisplayName: "",
        Abbreviation: "",
        DefaultBranch: "",
        CanUseMSPlaywrightService: null,
        RepoUrl: "",
        RepoAccessKey: "",
        RepoSSHPrivateKey: "",
        RepoSSHUrl: "",
        Id: ""
    });
    const [selectedImage, setSelectedImage] = useState<File | null>(null);

    useEffect(() => {
        if (data && data.Customers.length > 0) {
            setCustomer(data.Customers[0]);
            reset(data.Customers[0])
        }
    }, [data]);

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<CustomerInputs>()

    const editCustomerMutation = useMutation({
        mutationFn: (editCustomer: CustomerInputs) => updateCustomerById(editCustomer),
        onSettled: async () => {
            return await queryClient.invalidateQueries({ queryKey: ["customers"] })
        },
    });

    const uploadCustomerImage = useMutation({
        mutationFn: (editImage: UploadImageProps) => uploadImage(editImage),
        onSettled: async () => {
            await queryClient.invalidateQueries({ queryKey: ["customers"] })
        },
    });

    const OnSubmit: SubmitHandler<CustomerInputs> = async () => {
        try {
            await editCustomerMutation.mutateAsync(customer);
            if (selectedImage && customer) {
                await uploadCustomerImage.mutateAsync({ customerId: customer.Id, imageData: selectedImage });
                onHide();
            }
            onHide();
        } catch (error) {
            console.log("Error", error);
            onHide();
        }
    };

    const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value, checked, type } = e.target;
        if (type === "checkbox") {
            setCustomer({ ...customer, [name]: checked })
        } else {
            setCustomer({ ...customer, [name]: value });
        }
    }

    const handleEditCustomerCancel = () => {
        onHide();
        reset();
    }

    return (
        <>
            <Modal
                onHide={handleEditCustomerCancel}
                show={show}
                className="create-customer-modal"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Container>
                    <Modal.Header closeButton className='border-bottom-0 pb-0'>
                        <Modal.Title className="fw-semibold" id="contained-modal-title-vcenter">
                            Edit Customer
                        </Modal.Title>
                    </Modal.Header>
                </Container>
                <Container>
                    {loading
                        ?
                        <ThreeDots wrapperClass="spinner-wrapper loading" color="#283342" height="80"
                            width="80" />
                        :
                        <Form noValidate onSubmit={handleSubmit(OnSubmit)}>
                            <Modal.Body className='pt-0 pb-0'>

                                <p>
                                    Please fill out the following details below.
                                </p>
                                <Row>
                                    <Form.Group as={Col} className="mb-3" controlId="validationCustom01">
                                        <Form.Label style={{ fontWeight: "500" }}>Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            defaultValue={customer.DisplayName}
                                            {...register("DisplayName", { onChange: handleOnChange, required: true })}
                                            className={
                                                errors.DisplayName
                                                    ? "is-invalid form-control"
                                                    : "form-control"
                                            }

                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} className="mb-3" controlId="validationCustom02">
                                        <Form.Label style={{ fontWeight: "500" }}>Abbreviation</Form.Label>
                                        <fieldset disabled>
                                            <Form.Control
                                                required
                                                type="text"
                                                defaultValue={customer.Abbreviation}
                                                {...register("Abbreviation", { onChange: handleOnChange, required: true })}
                                                className={
                                                    errors.Abbreviation
                                                        ? "is-invalid form-control"
                                                        : "form-control"
                                                }
                                            />
                                        </fieldset >
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Form.Group as={Col} className="mb-3" controlId="validationCustom03">
                                        <Form.Label style={{ fontWeight: "500" }}>Default Branch</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            defaultValue={customer.DefaultBranch}
                                            {...register("DefaultBranch", { onChange: handleOnChange, required: true })}
                                            className={
                                                errors.DefaultBranch
                                                    ? "is-invalid form-control"
                                                    : "form-control"
                                            }
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} className="mb-3" controlId="validationCustom04">
                                        <Form.Label style={{ fontWeight: "500" }}>Use MS Playwright Service</Form.Label>
                                        <Form.Check
                                            type="switch"
                                            {...register("CanUseMSPlaywrightService", { onChange: handleOnChange })}
                                            className="form-control border-0"
                                        />
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Form.Group as={Col} className="mb-3" controlId="validationCustom05">
                                        <Form.Label style={{ fontWeight: "500" }}>Repo URL</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            defaultValue={customer.RepoUrl}
                                            {...register("RepoUrl", { onChange: handleOnChange, required: true })}
                                            className={
                                                errors.RepoUrl
                                                    ? "is-invalid form-control"
                                                    : "form-control"
                                            }
                                        />
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Form.Group as={Col} className="mb-3" controlId="validationCustom06">
                                        <Form.Label style={{ fontWeight: "500" }}>Repo Access Key</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="Current Value Hidden"
                                            {...register("RepoAccessKey", { onChange: handleOnChange })}
                                            className={
                                                errors.RepoAccessKey
                                                    ? "is-invalid form-control"
                                                    : "form-control"
                                            }
                                        />
                                    </Form.Group>
                                </Row>
                                <Row>

                                    <Form.Group as={Col} className="mb-3" controlId="validationCustom07">
                                        <Form.Label style={{ fontWeight: "500" }}>Repo SSH Key</Form.Label>
                                        <Form.Control
                                            required
                                            as="textarea"
                                            rows={2}
                                            placeholder="Current Value Hidden"
                                            {...register("RepoSSHPrivateKey", { onChange: handleOnChange })}
                                            className={
                                                errors.RepoSSHPrivateKey
                                                    ? "is-invalid form-control"
                                                    : "form-control"
                                            }
                                        />
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Form.Group as={Col} className="mb-3" controlId="validationCustom08">
                                        <Form.Label style={{ fontWeight: "500" }}>Repo SSH URL</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            defaultValue={customer.RepoSSHUrl}
                                            {...register("RepoSSHUrl", { onChange: handleOnChange, required: true })}
                                            className={
                                                errors.RepoSSHUrl
                                                    ? "is-invalid form-control"
                                                    : "form-control"
                                            }
                                        />
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Form.Group controlId="formFile" className="mb-3">
                                        <Form.Label style={{ fontWeight: "500" }}>Upload Customer Logo</Form.Label>
                                        <UploadFile
                                            onSelectImage={(image: any) => setSelectedImage(image)}
                                        />
                                    </Form.Group>
                                </Row>
                            </Modal.Body >
                            <Modal.Footer className="border-top-0">
                                <Button variant="outline-primary" onClick={handleEditCustomerCancel}>Cancel</Button>
                                <Button variant="primary" type="submit">
                                    {editCustomerMutation.isPending
                                        ?
                                        < ThreeDots color="white" height="24" width="118" />
                                        :
                                        "Save Customer"}
                                </Button>
                            </Modal.Footer>
                        </Form>
                    }
                </Container >
            </Modal >
        </>
    )
}