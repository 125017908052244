import { useMsal } from "@azure/msal-react";
import { IPublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from "../Auth/authentication";

// SignOutButton Component returns a button that invokes a redirect logout when clicked
export default function Logout() {
    // useMsal hook will return the PublicClientApplication instance you provided to MsalProvider
    const { instance } = useMsal();

    function signOutClickHandler(instance: IPublicClientApplication) {
        const logoutRequest = {
            account: instance.getAccountByHomeId(msalConfig.auth.clientId),
        };
        instance.logoutRedirect(logoutRequest);
    };

    return (
        <div className="logout-list" onClick={() => signOutClickHandler(instance)}>Log Out</div>
    );
}