import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import ReactPaginate from "react-paginate";
import { capitalizeArrayFirstLetter } from "../../utils/helpers";
import { ThreeDots } from "react-loader-spinner";
import { updateUserProps, userTableProps } from "../../utils/data-types";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateUser } from "../../api/users";
import { Button } from "react-bootstrap";
import Checkboxes from "../../components/Checkboxes";
import { v4 as uuidv4 } from "uuid"
import { OPTIONS, POSSIBLEROLESARRAY } from "./utils/constants";

interface AssignProps {
    onHideModal: () => void;
    showModal?: boolean;
    assignCustomerRoles?: any;
    assignCustomerProps?: userTableProps | null | undefined;
    customerMngtData: Array<any>;
}

type CheckboxInputObject = {
    [key: string]: Array<string | null>;
}

export default function AssignCustomersOffCanvas(props: AssignProps) {

    const { assignCustomerRoles, assignCustomerProps, customerMngtData, showModal, onHideModal } = props;
    const [page, setPage] = useState<number>(0);
    const queryClient = useQueryClient();
    const [showOptions, setShowOptions] = useState<string>(OPTIONS[9]);
    const n = parseInt(showOptions);

    // Mutation Update Customer Roles
    const assignCustomerRolesMutation = useMutation({
        mutationFn: (data: updateUserProps) => updateUser(data),
        onSuccess: () => reset,
        onSettled: async () => {
            await queryClient.invalidateQueries({ queryKey: ["Users"] })
            await queryClient.invalidateQueries({ queryKey: ["userMngtData"] })
        },
    });

    const { handleSubmit, reset, control, watch } = useForm({
        defaultValues: assignCustomerRoles
    });

    const watchAllFields = watch();

    // Iterates through checkbox fields and removes null and undefined values from array
    const removeNullValues = (assignedRoles: CheckboxInputObject) => {
        const result: { [key: string]: Array<string> } = {};

        for (const key in assignedRoles) {
            if (assignedRoles.hasOwnProperty(key)) {
                const filteredArray = assignedRoles[key].filter((value): value is string => value !== null && value !== undefined);
                result[key] = filteredArray;
            }
        }

        return result;

    }
    // Keeps track of role values when assigning user roles to a customer
    const rolesObject = removeNullValues(watchAllFields);

    // Submit assign customers
    const onSubmit = async (data: CheckboxInputObject) => {
        const submittedRoles = removeNullValues(data)
        try {
            await assignCustomerRolesMutation.mutateAsync({ userId: assignCustomerProps?.Id, roleAssignmentData: submittedRoles })
            onHideModal()
            reset();
        } catch (error) {
            console.log("Error", error)
            onHideModal()
            reset();
        }
    }

    // Merges logged in users manageable customers array with users object from
    //  the user table (passed prop). Returns customer object with all properties 
    // necessary to render customer table in the canvas body.
    const updateCustomers = (userTable: userTableProps | null | undefined, customers: Array<any>) => {
        const updatedRoles: { [key: string]: any } = {};

        if (userTable === null || userTable === undefined) {
            return updatedRoles
        }

        customers?.forEach((customer: { Abbreviation: string; DisplayName: string; }) => {
            if (userTable?.CustomerRoles.hasOwnProperty(customer.Abbreviation)) {
                updatedRoles[customer.Abbreviation] = {
                    Abbreviation: customer.Abbreviation,
                    DisplayName: customer.DisplayName,
                    Roles: userTable.CustomerRoles[customer.Abbreviation].Roles
                }
            } else {
                // Add possible customer with empty Roles array
                updatedRoles[customer.Abbreviation] = {
                    Abbreviation: customer.Abbreviation,
                    DisplayName: customer.DisplayName,
                    Roles: []
                }
            }
        });

        return updatedRoles
    };

    // returns a customers object with all possible roles and props
    const manageableCustomers = updateCustomers(assignCustomerProps, customerMngtData);

    // returns an array with customer keys available for the current signed in user
    const manageableCustomersArray = Object.keys(manageableCustomers)

    // filters manageable customers used for pagination
    const filteredCustomers = manageableCustomersArray.filter((_item: any, index: number) => {
        return (index >= page * n) && (index < (page + 1) * n);
    });

    const handleCanvasReset = () => {
        reset();
        onHideModal();
    };

    return (
        <>
            <Offcanvas show={showModal} onHide={handleCanvasReset} placement={"end"}>
                <div className="offcanvas-container overflow-auto">

                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>
                            <h4>Assign Customers</h4>
                        </Offcanvas.Title>
                    </Offcanvas.Header>

                    <div className="sub-title-canvas pb-4">Assigning customers to
                        <span className="sub-title-username"> {assignCustomerProps?.FirstName} {assignCustomerProps?.LastName}</span>
                    </div>

                    <div className="options-select-dropdown d-flex align-items-center justify-content-start flex-shrink-0">
                        <label className="me-1">Show</label>
                        <select className="form-select options-select-dropdown"
                            onChange={(e) => setShowOptions(e.target.value)}
                            defaultValue={showOptions}
                        >
                            {OPTIONS.map((option, idx) => (
                                <option key={idx}>{option}</option>
                            ))}
                        </select>
                    </div>

                    <Offcanvas.Body className="pt-4">
                        <div className="card">
                            <table className="table table-hover align-middle tbl">
                                <thead className="table-light">
                                    <tr>
                                        <th scope="col" className="customer_name">Customer Name</th>
                                        <th scope="col" className="assign_role">Assign Role</th>
                                        <th scope="col" className="customer_roles"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {manageableCustomersArray.length > 0 ?
                                        (filteredCustomers.map((customerKey: string) =>
                                            <tr key={customerKey}>
                                                <td className="customer_name" >
                                                    {manageableCustomers[customerKey].DisplayName}
                                                </td>

                                                {rolesObject[customerKey].length > 0
                                                    ?
                                                    <td className="assign_role">
                                                        {capitalizeArrayFirstLetter(rolesObject[customerKey]).join(", ")}
                                                    </td>
                                                    :
                                                    <td className="assign_role text-secondary">
                                                        {"No Role Assigned"}
                                                    </td>}

                                                <td
                                                    className="customer_roles">
                                                    <div className="dropdown">
                                                        <button type="button" className="dropdown-toggle roles" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside" />

                                                        <form
                                                            className="dropdown-menu">
                                                            <Checkboxes
                                                                key={uuidv4()}
                                                                options={POSSIBLEROLESARRAY}
                                                                control={control}
                                                                name={`${customerKey}`}
                                                            />
                                                        </form>
                                                    </div>
                                                </td>
                                            </tr>))
                                        :
                                        (<tr>
                                            <td className="text-danger" colSpan={3}>No customers available</td>
                                        </tr>)}
                                </tbody>
                            </table>
                        </div >

                        <nav aria-label="Page navigation">
                            <ReactPaginate
                                onPageChange={(event) => setPage(event.selected)}
                                pageRangeDisplayed={2}
                                marginPagesDisplayed={3}
                                pageCount={Math.ceil(manageableCustomersArray.length / n)}
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                previousLabel={<FontAwesomeIcon icon={faArrowLeft} style={{ color: "#979CA8", }} />}
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                nextLabel={<FontAwesomeIcon icon={faArrowRight} style={{ color: "#1D1F23", }} />}
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                                renderOnZeroPageCount={null}
                                breakLabel="..."
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                                activeClassName="active"
                                containerClassName="pagination justify-content-end"
                            />
                        </nav>
                    </Offcanvas.Body >
                    <div className="d-flex align-items-end justify-content-end mt-5rem">
                        <Button variant="outline-primary" onClick={handleCanvasReset}>Cancel</Button>
                        <Button variant="primary" className="ms-3" onClick={handleSubmit(onSubmit)}>
                            {assignCustomerRolesMutation.isPending
                                ?
                                < ThreeDots color="white" height="24" width="118" />
                                :
                                "Assign Customers"}
                        </Button>
                    </div>
                </div>
            </Offcanvas >
        </>
    );
}

