import { useQuery } from "@tanstack/react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState } from "react";
import { getHistory } from "../../api/history";
import { CustomerObject, PlaywrightTestTableProps, QueryResponse } from "../../utils/data-types";
import { ThreeDots } from "react-loader-spinner";
import { useParams } from "react-router-dom";
import { convertToCst, invariant } from "../../utils/helpers";
import LinkToContent from "./LinkToContent";
import ReactPaginate from "react-paginate";
import DataCounter from "../../components/DataCounter";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import DeleteRecordModal from "./DeleteRecordModal";
import { useCheckPermissions } from "../../hooks/useCheckPermissions";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import classNames from "classnames";

export default function PlaywrightTestTable() {
    const [modalShow, setModalShow] = useState<boolean>(false);
    const [deleteRecordName, setDeleteRecordName] = useState<string>("");
    const { customer, id } = useParams();
    const [page, setPage] = useState<number>(0);
    const { data: userPermissionData } = useCheckPermissions();
    const [isActive, setActive] = useState<boolean>(false);
    const n = 10;

    // check if undefined
    invariant(customer);
    invariant(id);

    // Expand table column
    const toggleExpandTable = () => {
        setActive(!isActive)
    }

    const expandableColumn = classNames({
        "records_metadata": true,
        "expand": isActive
    })

    const customersAppProps: CustomerObject = userPermissionData?.Customers

    // Check if passed values is in any of the User Permissions array
    let canDeleteRecord = false;
    for (const customerProps of Object.values(customersAppProps || {})) {
        if (customerProps.Id === id && customerProps.UserPermissions.includes("customer_mgmt")) {
            canDeleteRecord = true;
            break;
        }
    }

    // Get customers test record history
    const { data: historyData, isLoading, error, isError }: QueryResponse = useQuery({
        queryKey: [customer, id, "History"],
        queryFn: () => getHistory(id),
    });

    if (isLoading) {
        return <ThreeDots wrapperClass="spinner-wrapper" color="#283342" height="80"
            width="80" />
    }

    if (isError) {
        console.log("Error", error)
        return <h4 className="text-danger">Error: {error?.message}</h4>
    }

    // Filter data by n value
    const filterHistoryData = historyData.Items.filter((_item: any, index: number) => {
        return (index >= page * n) && (index < (page + 1) * n);
    })

    return (
        <>
            <DeleteRecordModal
                showDeleteRecordModal={modalShow}
                hideDeleteRecordModal={() => setModalShow(false)}
                deleteRecordName={deleteRecordName}
            />

            <DataCounter
                dataCount={filterHistoryData.length}
                typeOfData="automated tests"
                totalCount={historyData.Items.length}
            />

            <div className="card">
                <div className="table-responsive">
                    <table className="table table-hover align-middle tbl">
                        <thead className="table-light">
                            <tr>
                                <th scope="col" className="records_rundate">Date &amp; Time (CST)</th>
                                <th scope="col" className="logs_link">Logs</th>
                                <th scope="col" className="records_runduration">Duration</th>
                                <th scope="col" className="records_tests">Tests</th>
                                <th scope="col" className="records_status">Status</th>
                                <th scope="col" className="records_timeout">Timeout</th>
                                <th scope="col" className="records_flaky">Flaky</th>
                                <th scope="col" className={expandableColumn}>Branch</th>
                                <th scope="col" className={expandableColumn}>Username</th>
                                <th scope="col" className="records_actions">Run Results</th>
                                <th scope="col" className="records_delete">
                                    <div className="d-flex justify-content-center">
                                        <div role="button" onClick={toggleExpandTable}>
                                            {!isActive
                                                ?
                                                <div className="material-symbols-outlined" style={{ fontSize: "16px" }}>
                                                    open_in_full
                                                </div>
                                                :
                                                <span className="material-symbols-outlined d-flex">
                                                    collapse_content
                                                </span>
                                            }
                                        </div>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {historyData.Items.length > 0 ?
                                (filterHistoryData.map((record: PlaywrightTestTableProps, index: string) =>
                                    <tr key={index}>
                                        <td className="records_rundate">{convertToCst(record.RunDateTimeUtc)}</td>
                                        <td className="logs_link">
                                            <LinkToContent id={id} resultName={record.Name} fileName={record.LogName} title="View Logs" />
                                        </td>
                                        <td className="records_runduration">{record.RunDuration}</td>
                                        <td className="records_tests">{record.TestCount}</td>
                                        {record.FailCount === -1
                                            ?
                                            <td className="records_status">
                                                <OverlayTrigger
                                                    overlay={
                                                        <Tooltip >
                                                            incomplete
                                                        </Tooltip>
                                                    }
                                                    placement="top"
                                                >
                                                    {({ ref, ...triggerHandler }) => (

                                                        <div className="incomplete-status" {...triggerHandler} ref={ref}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 13 12" fill="none">
                                                                <path d="M6.16821 6.85171L3.18722 9.8327C3.07569 9.94423 2.93374 10 2.76137 10C2.589 10 2.44705 9.94423 2.33551 9.8327C2.22398 9.72117 2.16821 9.57921 2.16821 9.40684C2.16821 9.23447 2.22398 9.09252 2.33551 8.98099L5.3165 6L2.33551 3.01901C2.22398 2.90748 2.16821 2.76553 2.16821 2.59316C2.16821 2.42079 2.22398 2.27883 2.33551 2.1673C2.44705 2.05577 2.589 2 2.76137 2C2.93374 2 3.07569 2.05577 3.18722 2.1673L6.16821 5.14829L9.1492 2.1673C9.26074 2.05577 9.40269 2 9.57506 2C9.74743 2 9.88938 2.05577 10.0009 2.1673C10.1124 2.27883 10.1682 2.42079 10.1682 2.59316C10.1682 2.76553 10.1124 2.90748 10.0009 3.01901L7.01992 6L10.0009 8.98099C10.1124 9.09252 10.1682 9.23447 10.1682 9.40684C10.1682 9.57921 10.1124 9.72117 10.0009 9.8327C9.88938 9.94423 9.74743 10 9.57506 10C9.40269 10 9.26074 9.94423 9.1492 9.8327L6.16821 6.85171Z" fill="#A62621" />
                                                            </svg>
                                                        </div>
                                                    )}

                                                </OverlayTrigger>
                                            </td>
                                            :
                                            <td className="records_status complete">
                                                <OverlayTrigger
                                                    overlay={
                                                        <Tooltip >
                                                            complete
                                                        </Tooltip>
                                                    }
                                                    placement="top"
                                                >
                                                    {({ ref, ...triggerHandler }) => (
                                                        <div className="complete-status" {...triggerHandler} ref={ref}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 13 12" fill="none">
                                                                <path d="M4.89962 7.53254L9.2772 3.15496C9.38051 3.05165 9.50318 3 9.64523 3C9.78727 3 9.90995 3.05165 10.0133 3.15496C10.1166 3.25826 10.1682 3.38094 10.1682 3.52299C10.1682 3.66503 10.1166 3.78771 10.0133 3.89101L5.26119 8.64308C5.15788 8.74638 5.03736 8.79804 4.89962 8.79804C4.76188 8.79804 4.64135 8.74638 4.53805 8.64308L2.31697 6.422C2.21367 6.3187 2.16417 6.19602 2.16847 6.05398C2.17278 5.91193 2.22658 5.78926 2.32989 5.68595C2.43319 5.58264 2.55587 5.53099 2.69791 5.53099C2.83996 5.53099 2.96263 5.58264 3.06594 5.68595L4.89962 7.53254Z" fill="#307233" />
                                                            </svg>
                                                        </div>
                                                    )}
                                                </OverlayTrigger>
                                            </td>}
                                        <td className="records_timeout">{record.TimeoutCount}</td>
                                        <td className="records_flaky">{record.FlakyCount}</td>
                                        <OverlayTrigger
                                            overlay={
                                                <Tooltip >
                                                    {record.RepoTag}
                                                </Tooltip>
                                            }
                                            placement="top"
                                        >
                                            {({ ref, ...triggerHandler }) => (

                                                <td {...triggerHandler}>
                                                    <div className={expandableColumn} ref={ref}>
                                                        {record.RepoTag}
                                                    </div>
                                                </td>
                                            )}
                                        </OverlayTrigger>

                                        <OverlayTrigger
                                            overlay={(props) => (
                                                <Tooltip {...props}>
                                                    {record.AgentName}
                                                </Tooltip>
                                            )}

                                            placement="top"
                                        >
                                            <td className={expandableColumn}>
                                                {record.AgentName}
                                            </td>
                                        </OverlayTrigger>


                                        <td className="records_actions">
                                            <LinkToContent id={id} resultName={record.Name} fileName={record.IndexName} title="View Results" />
                                        </td>
                                        {canDeleteRecord
                                            ?
                                            <td className="records_delete">
                                                <button className="border-bg-none" onClick={() => { setDeleteRecordName(record.Name); setModalShow(true) }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash3" viewBox="0 0 16 16">
                                                        <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                                                    </svg>
                                                </button>
                                            </td>
                                            :
                                            <td></td>
                                        }
                                    </tr>
                                )) :
                                <tr>
                                    <td className="text-danger" colSpan={9}>No records to show</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            <nav aria-label="Page navigation">
                <ReactPaginate
                    onPageChange={(event) => setPage(event.selected)}
                    pageRangeDisplayed={2}
                    marginPagesDisplayed={3}
                    pageCount={Math.ceil(historyData.Items.length / n)}
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousLabel={<FontAwesomeIcon icon={faArrowLeft} style={{ color: "#979CA8", }} />}
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextLabel={<FontAwesomeIcon icon={faArrowRight} style={{ color: "#1D1F23", }} />}
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    renderOnZeroPageCount={null}
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    activeClassName="active"
                    containerClassName="pagination justify-content-end"
                />
            </nav>
        </>
    )
}

