import { NavLink } from "react-router-dom";
import ParadigmLogo from "../../assets/img/logo-color=orange.png"
import User from "./User";
import { useCheckPermissions } from "../../hooks/useCheckPermissions";
import { CustomerObject } from "../../utils/data-types";


export default function NavBar() {

    const { data: userPermissionData } = useCheckPermissions();

    const customersAppProps: CustomerObject = userPermissionData?.Customers

    // Check if passed values is in any of the Super User Permissions array
    // if not then iterate through customers object and check for permission
    let isUserMgmt = false;
    if (userPermissionData && userPermissionData.SuperUserPermissions.includes("user_mgmt")) {
        isUserMgmt = true;
    } else {
        for (const customerProps of Object.values(customersAppProps || {})) {
            if (customerProps.UserPermissions.includes("user_mgmt")) {
                isUserMgmt = true;
                break;
            }
        }
    }

    return (
        <>
            <nav className="navbar fixed-top paradigm-blue">
                <div className="container">
                    <div className="d-flex align-items-center w-100 mt-1 mb-1">
                        <NavLink to={"/customers"}> <img src={ParadigmLogo} className="paradigm-nav-logo" alt="Paradigm Logo" /></NavLink>
                        <div className="nav-link-action d-flex flex-grow-1 ms-4">
                            <div className="nav-link-child ms-1 me-1">
                                <NavLink to={"/customers"} className="text-decoration-none text-light ps-3 pe-3">Customers</NavLink>
                            </div>
                            {
                                isUserMgmt
                                    ?
                                    <div className="nav-link-child ms-1 me-1">
                                        <NavLink to={"/users"} className="text-decoration-none text-light ps-3 pe-3">Users</NavLink>
                                    </div>
                                    :
                                    null
                            }

                        </div>
                        <User />
                    </div>
                </div>
            </nav>
        </>
    );
}
