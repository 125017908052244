import { useQuery } from "@tanstack/react-query";
import { QueryResponse } from "../../utils/data-types";
import { getHistoryBlob } from "../../api/history";
import { ThreeDots } from "react-loader-spinner";
interface historyBlobParams {
    id: string,
    resultName: string;
    fileName: string;
    title: string;
}

export default function LinkToContent({ id, resultName, fileName, title }: historyBlobParams) {
    const { data: linkInfo, isLoading, isError, error }: QueryResponse = useQuery({
        queryKey: [id, resultName, fileName],
        queryFn: () => getHistoryBlob(id, resultName, fileName),
        staleTime: 3600000 // 1 hour
    });

    if (isLoading) {
        return <ThreeDots color="#283342" height="50"
            width="50" />
    }

    if (isError) {
        console.log("Error", error)
    }

    return (
        <>
            {linkInfo
                ?
                <a className="text-decoration-none text-primary" rel="noreferrer" target="_blank" href={linkInfo}>{title}</a>
                :
                <div className="text-decoration-none text-body fst-italic">Not Available</div>
            }
        </>
    );
}