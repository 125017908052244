import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { AnyObject } from "./data-types";

// Remove whitespace, symbols and capitalize strings
export const RemoveCharCapitalize = (text: string | undefined): string => {
  if (!text) {
    return "";
  }
  // Remove leading and trailing whitespace
  text = text.trim();

  // Replace %20 with _
  text = text.replace(/%20/g, "_");

  // Split text by character _
  const result = text.split("_");

  // Capitalize word and join into string
  return result.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ");

};

// Remove whitespace and capitalize first letter of string
export const TrimAndCapitalize = (text: string): string => {

  // remove whitespace
  const trimmedString = text.trim();

  // Capitalize word
  return trimmedString.charAt(0).toUpperCase() + trimmedString.slice(1);

}

// Capitalize first letter in an array of strings and separate by underscore if it exists
export const capitalizeArrayFirstLetter = (array: Array<string> | null | undefined) => {

  if (array === null || array === undefined) {
    return [];
  }

  const updatedStr = array.map(str => {
    // Split the string by underscores
    const word = str.split("_");

    // Capitalize each word and return array
    const capitalizedWord = word.map(word => word.charAt(0).toUpperCase() + word.slice(1));

    // Join words in array with spaces
    return capitalizedWord.join(" ")

  });

  return updatedStr

}

// Takes in UTC datetime(string) and converts to CST(string)
export const convertToCst = (RunDateTimeUtc: string): string => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  return dayjs.tz(dayjs(RunDateTimeUtc), "America/Chicago").format("MM/DD/YYYY, h:mm:ss A")
}

// returns index.html document title
export const documentTitle = (docTitle: string | undefined) => {
  if (!docTitle) {
    throw new Error(
      "Unexpected error: Missing Document Title"
    )
  }
  return document.title = `ATP | ${docTitle}`
}

// returns valid {key: value} pairs object 
export function resolvePropertyValue(obj: AnyObject, key: string, value: string | null | undefined | boolean) {
  return {
    ...obj,
    ...(value !== null && value !== undefined ? { [key]: value } : {})
  };
}

// return error if undefined
export function invariant(value: unknown): asserts value {
  if (value) return;
  throw new Error("Invariant violation");
}