
import ReactDOM from 'react-dom/client';
import React from 'react'
import App from './App';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import "./styles/index.css"


const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // globally default to 20 seconds
      staleTime: 1000 * 20,
    },
  },
})

// Components that need to be render once go in here
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </React.StrictMode>
);