import * as signalR from "@microsoft/signalr";
import appSettings from "../appsettings.json"
import axiosApiInstance from "../api/config/axios-config";
import { RUN_CANCELLED, RUN_FINISHED, RUN_STARTED } from "./data-types";
export interface EventTypeProps {
    eventType: string;
    data: {
        EventData: {
            WorkId: string;
        }
    }
}

interface Connector {
    events: (onMessageReceived: (eventData: EventTypeProps) => void) => void;
}

// Declare start signal R connection function
async function start(connection: signalR.HubConnection) {
    try {
        await connection.start();
        console.log(`Listening to Events. ConnectionId: ${connection.connectionId}`);
    } catch (err) {
        console.error(err);
    }
}

// event types post connection
function createConnectorEvent(connection: signalR.HubConnection, onMessageReceived: (eventData: EventTypeProps) => void): void {
    connection.on(RUN_STARTED, (message) => {
        onMessageReceived(message);
    });
    connection.on(RUN_CANCELLED, (message) => {
        onMessageReceived(message);
    });
    connection.on(RUN_FINISHED, (message) => {
        onMessageReceived(message);
    });
}

// negotiate entry, pass credentials.
async function createConnector(customerId: string): Promise<Connector> {
    try {
        const response = await axiosApiInstance
            .post(`${appSettings.WebApi.BaseAddress}${appSettings.WebApi.NegotiateEnpoint}`, {}, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

        const hubUrl: string = response.data.url;
        const accessToken: string = response.data.accessToken;

        const connection: signalR.HubConnection = new signalR.HubConnectionBuilder()
            .withUrl(hubUrl, {
                accessTokenFactory: () => accessToken
            })
            .withAutomaticReconnect()
            // .configureLogging(signalR.LogLevel.Information)
            .build();

        // Call to start connection function.
        await start(connection);

        if (!connection.connectionId || connection.connectionId === "")
        {
            throw new Error(`Invalid connection id returned from negotiate. NegotiateResponse: ${JSON.stringify(response)} Connection: ${JSON.stringify(connection)}`);
        }

        // joining signal R group
        await axiosApiInstance
            .post(`${appSettings.WebApi.BaseAddress}${appSettings.WebApi.AllCustomersEndpoint}/${customerId}/events/${connection.connectionId}/join`,
                {},
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });

        // return events
        const events = (onMessageReceived: (eventData: EventTypeProps) => void): void => {
            createConnectorEvent(connection, onMessageReceived);

        };

        return {
            events,
        };
    } catch (err) {
        console.error(err);
        throw err;
    }
};

// caller function
async function getConnectionInstance(customerId: string): Promise<Connector> {
    const instance: Connector = await createConnector(customerId);
    return instance;
};

export default getConnectionInstance;