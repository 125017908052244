import { ReactNode } from 'react';
import { initAuth, msalInstance } from './authentication';
import { MsalProvider, MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";


initAuth(); // should be called once per application, so called here outside of react lifecycle.

interface AuthRequiredProps {
  children: ReactNode,
  enabled: boolean
}

export default function ParadigmAuthRequired({ children, enabled }: AuthRequiredProps) {
  return (
    enabled ?
      <MsalProvider instance={msalInstance}>
        <MsalAuthenticationTemplate
          interactionType={InteractionType.Redirect}
        >
          {children}
        </MsalAuthenticationTemplate>
      </MsalProvider>
      : <>{children}</>
  )
}