interface ButtonProps {
    buttonGroup: boolean;
    title: string;
    className: string;
    btnGrooupClassName?: string;
    onClick: () => void;
    groupOnClick?: () => void | undefined;
}

export default function ToggleableButton({ buttonGroup, title, className, btnGrooupClassName, onClick, groupOnClick }: ButtonProps) {
    if (buttonGroup) {
        return (
            <div className="btn-group" role="group" aria-label={title}>
                <button className={className} onClick={onClick}>{title}</button>
                <button type="button" className={`${btnGrooupClassName} dropdown-toggle`} style={{ "marginLeft": "0.0625rem" }} onClick={groupOnClick}></button>
            </div>
        )
    }
    return (
        <button className={className} onClick={onClick}>{title}</button>
    );

}