import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Col, Container, Form, Row } from 'react-bootstrap';
import UploadFile from "./UploadFile";
import { useForm, SubmitHandler } from "react-hook-form"
import { addCustomer } from "../../api/customer";
import { CustomerInputs, UploadImageProps } from "../../utils/data-types";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ThreeDots } from "react-loader-spinner";
import { uploadImage } from "../../api/images";

export default function CreateCustomerModal(props: any) {
    const { onHide } = props;
    const queryClient = useQueryClient();
    const [selectedImage, setSelectedImage] = useState<File | null>(null);
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<CustomerInputs>()

    const createCustomer = useMutation({
        mutationFn: (inputData: CustomerInputs) => addCustomer(inputData),
        onSettled: async () => {
            await queryClient.invalidateQueries({ queryKey: ["customers"] })
        },
    });

    const uploadCustomerImage = useMutation({
        mutationFn: (uploadImageData: UploadImageProps) => uploadImage(uploadImageData),
        onSettled: async () => {
            await queryClient.invalidateQueries({ queryKey: ["customers"] })
        },
    });

    const OnSubmit: SubmitHandler<CustomerInputs> = async (inputData) => {
        try {
            const customerResponse = await createCustomer.mutateAsync(inputData);
            if (selectedImage && customerResponse) {
                await uploadCustomerImage.mutateAsync({ customerId: customerResponse.Customer.Id, imageData: selectedImage });
                onHide();
                reset();
            }
            onHide();
            reset();
        } catch (error) {
            console.log("Error", error);
            onHide();
            reset();
        }
    };

    const handleCreateCustomerCancel = () => {
        onHide();
        reset();
    }

    return (
        <>
            <Modal
                {...props}
                onHide={handleCreateCustomerCancel}
                className="create-customer-modal"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Container>
                    <Modal.Header closeButton className='border-bottom-0 pb-0'>
                        <Modal.Title className="fw-semibold" id="contained-modal-title-vcenter">
                            Create Customer
                        </Modal.Title>
                    </Modal.Header>
                </Container>
                <Container>
                    <Form noValidate onSubmit={handleSubmit(OnSubmit)}>
                        <Modal.Body className='pt-0 pb-0'>
                            <p>
                                Please fill out the following details below.
                            </p>
                            <Row>
                                <Form.Group as={Col} className="mb-3" controlId="validationCustom01">
                                    <Form.Label style={{ fontWeight: "500" }}>Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        {...register("DisplayName", { required: true })}
                                        className={
                                            errors.DisplayName
                                                ? "is-invalid form-control"
                                                : "form-control"
                                        }
                                    />
                                </Form.Group>
                                <Form.Group as={Col} className="mb-3" controlId="validationCustom02">
                                    <Form.Label style={{ fontWeight: "500" }}>Abbreviation</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        {...register("Abbreviation", { required: true })}
                                        className={
                                            errors.Abbreviation
                                                ? "is-invalid form-control"
                                                : "form-control"
                                        }

                                    />
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group as={Col} className="mb-3" controlId="validationCustom03">
                                    <Form.Label style={{ fontWeight: "500" }}>Default Branch</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        {...register("DefaultBranch", { required: true })}
                                        className={
                                            errors.DefaultBranch
                                                ? "is-invalid form-control"
                                                : "form-control"
                                        }
                                    />
                                </Form.Group>
                                <Form.Group as={Col} className="mb-3" controlId="validationCustom04">
                                    <Form.Label style={{ fontWeight: "500" }}>Use MS Playwright Service</Form.Label>
                                    <Form.Check
                                        type="switch"
                                        {...register("CanUseMSPlaywrightService")}
                                        className="form-control border-0"
                                    />
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group as={Col} className="mb-3" controlId="validationCustom05">
                                    <Form.Label style={{ fontWeight: "500" }}>Repo URL</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        {...register("RepoUrl", { required: true })}
                                        className={
                                            errors.RepoUrl
                                                ? "is-invalid form-control"
                                                : "form-control"
                                        }
                                    />
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group as={Col} className="mb-3" controlId="validationCustom06">
                                    <Form.Label style={{ fontWeight: "500" }}>Repo Access Key</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        {...register("RepoAccessKey", { required: true })}
                                        className={
                                            errors.RepoAccessKey
                                                ? "is-invalid form-control"
                                                : "form-control"
                                        }
                                    />
                                </Form.Group>
                            </Row>
                            <Row>

                                <Form.Group as={Col} className="mb-3" controlId="validationCustom07">
                                    <Form.Label style={{ fontWeight: "500" }}>Repo SSH Key</Form.Label>
                                    <Form.Control
                                        required
                                        as="textarea"
                                        rows={2}
                                        {...register("RepoSSHPrivateKey", { required: true })}
                                        className={
                                            errors.RepoSSHPrivateKey
                                                ? "is-invalid form-control"
                                                : "form-control"
                                        }
                                    />
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group as={Col} className="mb-3" controlId="validationCustom08">
                                    <Form.Label style={{ fontWeight: "500" }}>Repo SSH URL</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        {...register("RepoSSHUrl", { required: true })}
                                        className={
                                            errors.RepoSSHUrl
                                                ? "is-invalid form-control"
                                                : "form-control"
                                        }
                                    />
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group controlId="formFile" className="mb-3">
                                    <Form.Label style={{ fontWeight: "500" }}>Upload Customer Logo</Form.Label>
                                    <UploadFile onSelectImage={(image: File) => setSelectedImage(image)} />
                                </Form.Group>
                            </Row>
                        </Modal.Body >
                        <Modal.Footer className="border-top-0">
                            <Button variant="outline-primary" onClick={handleCreateCustomerCancel}>Cancel</Button>
                            <Button variant="primary" type="submit">
                                {createCustomer.isPending || uploadCustomerImage.isPending
                                    ?
                                    < ThreeDots color="white" height="24" width="118" />
                                    :
                                    "Create Customer"}
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Container >
            </Modal >
        </>
    )
}