import ParadigmAuthRequired from './features/Auth/ParadigAuthRequired';
import { HashRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Customers from './features/Customers/Customers';
import NavBar from './features/Navbar/NavBar';
import PlaywrightTest from './features/PlaywrightTest/PlaywrightTest';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import NotFound from './features/Errors/NotFound';
import Users from "./features/Users/Users";

export default function App() {
  return (
    <>
      <ParadigmAuthRequired enabled={true}>
        <Router>
          <NavBar />
          <Routes>
            <Route path="/" element={<Navigate to="/customers" replace />} />
            <Route path={"/customers"} element={<Customers />} />
            <Route path={"/users"} element={<Users />} />
            <Route path={"customers/:customer/:id"} element={<PlaywrightTest />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Router>
        <ReactQueryDevtools initialIsOpen={false} />
      </ParadigmAuthRequired>
    </>
  );
};