import axiosApiInstance from "./config/axios-config"
import appSettings from "../appsettings.json"
export const getHistory = async (customerId: string): Promise<any> => {
  const response = await axiosApiInstance.get(`${appSettings.WebApi.BaseAddress}${appSettings.WebApi.AllCustomersEndpoint}/${customerId}/history`)
  return response.data

}

export const getHistoryBlob = async (customerId: string, resultName: string, fileName: string): Promise<any> => {
  const response = await axiosApiInstance.get(`${appSettings.WebApi.BaseAddress}${appSettings.WebApi.AllCustomersEndpoint}/${customerId}/history/${resultName}/${fileName}`)
  return response.data.BlobUri

}

export const deleteRecord = async (customerId: string, resultName: string): Promise<any> => {
  const response = await axiosApiInstance.delete(`${appSettings.WebApi.BaseAddress}${appSettings.WebApi.AllCustomersEndpoint}/${customerId}/history/${resultName}`)
  return response
}