import { useQuery } from "@tanstack/react-query";
import { ThreeDots } from "react-loader-spinner";
import { getUsers } from "../../api/users";
import { QueryResponse, userTableProps } from "../../utils/data-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import ReactPaginate from "react-paginate";
import { useState } from "react";
import DataCounter from "../../components/DataCounter";
import DeleteUserModal from "./DeleteUserModal";
import AssignCustomerData from "./AssignCustomerData";

export default function UserTable() {
    const [deleteModalShow, setDeleteModalShow] = useState<boolean>(false);
    const [userInfoProps, setUserInfoProps] = useState<userTableProps | null>(null);
    const [offCanvasShow, setOffCanvasShow] = useState<boolean>(false);
    const [page, setPage] = useState<number>(0);
    const n = 10;

    // Get all users
    const { data: usersData, isLoading, error, isError }: QueryResponse = useQuery({
        queryKey: ["Users"],
        queryFn: () => getUsers(),

    });

    if (isLoading) {
        return <ThreeDots wrapperClass="spinner-wrapper" color="#283342" height="80" width="80" />

    }

    if (isError) {
        console.log("Error", error)
        return <h4 className="text-danger">Error: {error?.message}</h4>

    }

    const filterUserData = usersData.Users.filter((_item: any, index: number) => {
        return (index >= page * n) && (index < (page + 1) * n);
    })

    const handleDeleteModal = (userInfo: userTableProps) => {
        setUserInfoProps(userInfo);
        setDeleteModalShow(true)
    }

    const handleOffCanvas = (userInfo: userTableProps) => {
        setUserInfoProps(userInfo);
        setOffCanvasShow(true)
    }

    return (
        <>
            <DeleteUserModal
                show={deleteModalShow}
                onHide={() => setDeleteModalShow(false)}
                deleteUserInfo={userInfoProps}
            />

            <DataCounter
                dataCount={filterUserData.length}
                typeOfData="users"
            />

            {
                userInfoProps !== null
                    ?
                    <AssignCustomerData
                        showModal={offCanvasShow}
                        onHideModal={() => setOffCanvasShow(false)}
                        assignCustomerProps={userInfoProps}
                    />
                    : null
            }

            {/* Empty properties are set for spacing purposes */}
            <div className="card">
                <table className="table table-hover align-middle tbl">
                    <thead className="table-light">
                        <tr>
                            <th scope="col" className="first_name">First Name</th>
                            <th scope="col" className="last_name">Last Name</th>
                            <th scope="col"></th>
                            <th scope="col" className="email">Email</th>
                            <th scope="col" className="assign_customer">Assign Customer</th>
                            <th scope="col" className="user_actions"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {usersData.Users?.length > 0 ?
                            (filterUserData.map((userInfo: userTableProps) =>
                                <tr key={userInfo.Id}>
                                    <td className="first_name">{userInfo.FirstName}</td>
                                    <td className="last_name">{userInfo.LastName}</td>
                                    <td></td>
                                    <td className="email">{userInfo.Email}</td>
                                    <td className="assign_customer text-primary dots"><a role="button"
                                        onClick={() => handleOffCanvas(userInfo)}>{Object.keys(userInfo.CustomerRoles).length} Customers</a></td>
                                    <td className="user_actions">
                                        <div className="d-flex justify-content-end">
                                            <button className="border-bg-none d-flex align-items-center" onClick={() => handleDeleteModal(userInfo)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash3"
                                                    viewBox="0 0 16 16">
                                                    <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                                                </svg>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            )) :
                            <tr>
                                <td className="text-danger" colSpan={5}>No users available</td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div >

            <nav aria-label="Page navigation">
                <ReactPaginate
                    onPageChange={(event) => setPage(event.selected)}
                    pageRangeDisplayed={2}
                    marginPagesDisplayed={3}
                    pageCount={Math.ceil(usersData.Users.length / n)}
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousLabel={<FontAwesomeIcon icon={faArrowLeft} style={{ color: "#979CA8", }} />}
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextLabel={<FontAwesomeIcon icon={faArrowRight} style={{ color: "#1D1F23", }} />}
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    renderOnZeroPageCount={null}
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    activeClassName="active"
                    containerClassName="pagination justify-content-end"
                />
            </nav>
        </>
    );
}   