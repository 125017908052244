import { useEffect, useMemo, useState } from "react";
import PageTemplate from "../../components/PageTemplate";
import { useParams } from "react-router-dom";
import PlaywrightTestTable from "./PlaywrightTestTable";
import ToggleableButton from "../../components/ToggleableButton"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ThreeDots } from "react-loader-spinner";
import { CustomerObject, QueryResponse, RUN_CANCELLED, RUN_FINISHED, RUN_STARTED } from "../../utils/data-types";
import classNames from "classnames";
import AddTestTagModal from "./AddTestTagModal";
import { getTestRunStatus, initiateTestRun, cancelTestRun } from "../../api/test-runs";
import { invariant } from "../../utils/helpers";
import { useCheckPermissions } from "../../hooks/useCheckPermissions";
import getConnectionInstance, { EventTypeProps } from "../../utils/signalr-connection";

export default function PlaywrightTest() {
    const { customer, id } = useParams();
    const queryClient = useQueryClient();
    const [modalShow, setModalShow] = useState<boolean>(false);
    const [parentIsLoading, setParentIsLoading] = useState<boolean>(false);
    const [events, setEvents] = useState<EventTypeProps | null | undefined>(null);

    // Declaring Signal reusable constants 
    const eventWorkId = events ? events.data.EventData.WorkId : null;
    const eventType = events ? events.eventType : null;
    const signalStatusRunning: boolean = eventType === RUN_STARTED && eventWorkId !== null;
    const signalStatusCancelled: boolean = eventType === RUN_CANCELLED && eventWorkId !== null;
    const signalStatusFinished: boolean = eventType === RUN_FINISHED && eventWorkId !== null;

    // check if undefined
    invariant(customer);
    invariant(id);

    // Permissions check data
    // Check if passed values is in any of the User Permissions array
    const { data: userPermissionData } = useCheckPermissions();
    const customersAppProps: CustomerObject = userPermissionData?.Customers;

    let canRunTest = false;
    for (const customerProps of Object.values(customersAppProps || {})) {
        if (customerProps.Id === id && customerProps.UserPermissions.includes("run_tests")) {
            canRunTest = true;
            break;
        }
    }

    let canStopTest = false;
    for (const customerProps of Object.values(customersAppProps || {})) {
        if (customerProps.Id === id && customerProps.UserPermissions.includes("cancel_any_run")) {
            canStopTest = true;
            break;
        }
    }

    // Run connection to signal R when id changes
    useEffect(function callSignalRConnection() {
        const connectSignalR = async () => {
            try {
                if (id !== undefined) {
                    const connectorInstance =
                        await getConnectionInstance(id);

                    connectorInstance.events((eventData) => setEvents(eventData))
                };
            } catch (error) {
                console.error("Error initializing connector:", error);
            }
        };

        connectSignalR();
    }, [id]);

    // Invalidate history table when Signal finished event is triggered
    // Updates Status to 'testPending' boolean when test is finished
    useEffect(function updateHistoryTable() {
        if (signalStatusFinished) {
            queryClient.invalidateQueries({ queryKey: [customer, id, "History"] })
            queryClient.invalidateQueries({ queryKey: [customer, id, "Status"] })
        }
    }, [signalStatusFinished])

    // memoize the boolean value between renders if not changed
    const isTestStatusUpdated = useMemo(function signalRunEvent() {
        return (
            eventType === RUN_STARTED ||
            eventType === RUN_CANCELLED ||
            eventType === RUN_FINISHED) && eventWorkId !== null;
    }, [eventType, eventWorkId]);


    // Check test status when test run page loads
    const { data: testData, isLoading, error, isError, isFetching }: QueryResponse = useQuery({
        queryKey: [customer, id, "Status"],
        queryFn: () => getTestRunStatus(id),
        staleTime: 0
    });

    // Queries and mutations
    // 'status' & 'history' invalidate query extends the isPending boolean for better UI.
    const startTest = useMutation({
        mutationFn: () => initiateTestRun(id),
        onSuccess: () => Promise.all([
            queryClient.invalidateQueries({ queryKey: [customer, id, "Status"] })
        ]),
    });

    const stopTest = useMutation({
        mutationFn: (testWorkId: string) => cancelTestRun(id, testWorkId),
        onSuccess: () => Promise.all([
            queryClient.invalidateQueries({ queryKey: [customer, id, "History"] }),
            queryClient.invalidateQueries({ queryKey: [customer, id, "Status"] })
        ]),
        onSettled: async () => Promise.all([
            await queryClient.invalidateQueries({ queryKey: [customer, id, "Status"] }),
            await queryClient.invalidateQueries({ queryKey: [customer, id, "History"] }),
        ])
    });

    if (isLoading) {
        return <ThreeDots wrapperClass="spinner-wrapper" color="#283342" height="80"
            width="80" />
    }

    if (isError) {
        return <h4 className="text-danger" > Error: {error?.message}</h4>
    }

    // Declaring test data reusable constants 
    const QUEUED_STATUS = "Queued";
    const RUNNING_STATUS = "Running";
    const runItem = testData.RunItems[0];

    // testPending is checked when page loads and updates UI buttons status
    const testPending = runItem?.ItemStatus === QUEUED_STATUS || runItem?.Items[0]?.ItemStatus === RUNNING_STATUS;

    const handleRunTests = () => {
        try {
            if (!testPending) {
                startTest.mutate();
            }
        } catch (error) {
            console.error(error);
        }
    }

    const handleCancelTests = () => {
        if (testData.RunItems.length <= 0) {
            // No data to process
            return;
        }

        if (!testPending) {
            alert("Can't Cancel test")
            return;
        }

        try {
            if ((runItem.ItemStatus === QUEUED_STATUS && !runItem.CanBeCancelled)
                || (runItem.ItemStatus === RUNNING_STATUS && !runItem.Items[0].CanBeCancelled)) {
                return "Can't Cancel Test"
            }

            if (runItem.ItemStatus === QUEUED_STATUS) {
                // Stop queued item
                const queuedWorkId = runItem.WorkId
                stopTest.mutateAsync(queuedWorkId)
            }
            else {
                // Stop running item
                const runningWorkId = runItem.Items[0].WorkId
                stopTest.mutateAsync(runningWorkId)
            }
        } catch (error) {
            console.error(error)
        }

    }

    const testHandler = testPending ? () => handleCancelTests() : () => handleRunTests()

    // This function updates the loading state when a test is ran on 
    // a different branch. Different branch test are ran from <AddTestTagModal> component.
    // it syncs the parent and the child components when starting a test.  
    const handleMutationLoadingChange = (isChildMutationLoading: boolean) => {
        setParentIsLoading(isChildMutationLoading);
    }

    // Test status and classnames/style conditionals
    let testStatus = null;

    if (signalStatusRunning) {
        testStatus = <div className="complete-status">Run Started</div>;
    }
    if (signalStatusCancelled) {
        testStatus = <div className="incomplete-status">Run Cancelled</div>;
    }
    if (signalStatusFinished) {
        testStatus = <div className="complete-status">Run Finished</div>;
    }

    // UI Dynamic classnames depending on constants
    const btnTitle = classNames({
        "Run Tests": signalStatusFinished || !testPending,
        "Stop Tests": signalStatusRunning || testPending,
    })

    const btnStatus = classNames({
        "btn btn-primary": !testPending || signalStatusFinished,
        "btn btn-danger paradigm-danger": signalStatusRunning || testPending,
        "btn btn-danger paradigm-danger disabled": (testPending || signalStatusRunning) && !canStopTest
    })

    const btnGroupStatus = classNames({
        "btn btn-primary": !testPending || signalStatusFinished,
        "btn btn-danger paradigm-hot disabled": signalStatusRunning || testPending
    })

    // Run Button element with loading state
    const RunTestButton = startTest.isPending || stopTest.isPending || parentIsLoading || isFetching
        ?
        < ThreeDots color="#283342" height="30" width="80" />
        :
        <ToggleableButton
            buttonGroup={true}
            className={btnStatus}
            btnGrooupClassName={btnGroupStatus}
            title={btnTitle}
            onClick={testHandler}
            groupOnClick={() => setModalShow(true)}
        />

    return (
        <>
            <AddTestTagModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                onMutationLoadingChange={handleMutationLoadingChange}
            />

            <PageTemplate
                testStatus={isTestStatusUpdated ? testStatus : null}
                title={`${customer} Automated Test`}
                launchButton={canRunTest ? RunTestButton : null}
            >
                <PlaywrightTestTable />
            </PageTemplate>
        </>
    );
}