import { useState } from 'react';
import { useController } from "react-hook-form";
import { RemoveCharCapitalize } from "../utils/helpers";

interface CheckboxesProps {
    options: Array<string>,
    control: any,
    name: string
    defaultRoles?: string;
}

export default function Checkboxes({ options, control, name }: CheckboxesProps) {
    const { field } = useController({
        control,
        name
    });
    const [value, setValue] = useState(field.value || []);

    return (
        <>
            {options.map((option, index) => (
                <div
                    key={index}
                    className={value.includes(option) ? "form-check checked" : "form-check"}
                >
                    <input
                        onChange={(e) => {
                            const valueCopy = [...value];

                            // update checkbox value
                            valueCopy[index] = e.target.checked ? e.target.value : null;

                            // send data to react hook form
                            field.onChange(valueCopy);

                            // update local state
                            setValue(valueCopy);

                        }}
                        key={option}
                        type="checkbox"
                        checked={value.includes(option)}
                        value={option}
                        className="form-check-input"
                        id={`dropdown${name}${option}`}

                    />
                    <label className="form-check-label" htmlFor={`dropdown${name}${option}`}>
                        {RemoveCharCapitalize(option)}
                    </label>
                </div>
            ))}
        </>
    );
};