import { useMutation, useQueryClient } from '@tanstack/react-query';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useParams } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { initiateTestRun } from '../../api/test-runs'
import { useState, ChangeEvent, useEffect } from 'react';
import { invariant } from "../../utils/helpers";

export default function AddTestTagModal(props: any) {
    const { onHide, onMutationLoadingChange } = props
    const { customer, id } = useParams();
    const queryClient = useQueryClient();
    const [input, setInput] = useState<string | null>(null);

    // check if undefined
    invariant(customer);
    invariant(id);

    const tagStartTest = useMutation({
        mutationFn: () => initiateTestRun(id, input),
        onSettled: async () => {
            return await queryClient.invalidateQueries({ queryKey: [customer, id, "Status"] })
        },
        mutationKey: [customer, id, "TaggedStartTest"]
    });

    useEffect(() => {
        onMutationLoadingChange(tagStartTest.isPending)
    }, [tagStartTest.isPending, onMutationLoadingChange])

    const handleRunTests = () => {
        try {
            tagStartTest.mutate();
            onHide();

        } catch (error) {
            console.error(error)
        }
    }

    return (
        <>
            <Modal
                {...props}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton className='border-bottom-0 pb-0'>
                    <Modal.Title id="contained-modal-title-vcenter" className='fw-semibold'>
                        Run Tests
                    </Modal.Title>

                </Modal.Header>
                <Modal.Body className='pt-0 pb-0'>
                    <p>
                        Please specify which tag or branch to run.
                    </p>
                    <Form>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label style={{ fontWeight: "500" }}>Tag or Branch</Form.Label>
                            <Form.Control
                                type="text"
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                    setInput(e.target.value.toLocaleLowerCase().replaceAll(" ", ""))
                                }
                                autoFocus
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer className='border-top-0'>
                    <Button variant="outline-primary" onClick={onHide}>Cancel</Button>
                    <Button className="primary" onClick={handleRunTests}>Run Test</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}