import axiosApiInstance from "./config/axios-config"
import appSettings from "../appsettings.json"
import { UploadImageProps } from "../utils/data-types"

export const uploadImage = async ({ customerId, imageData }: UploadImageProps): Promise<any> => {
    
    const formData = new FormData()
    formData.append('File', imageData, imageData.name)
        
    const response = await axiosApiInstance.put(`${appSettings.WebApi.BaseAddress}${appSettings.WebApi.AllCustomersEndpoint}/${customerId}/images/Logo`,
        { imageData },
        {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    return response

}