import { faTrashCan, faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { useEffect } from "react";
import { FileWithPath, useDropzone } from "react-dropzone";
interface ImageProps {
    onSelectImage: (image: File) => void
}

export default function UploadFile({ onSelectImage }: ImageProps) {

    const { acceptedFiles, getRootProps, getInputProps, isDragActive, } = useDropzone({
        maxFiles: 1,
        accept: {
            'image/png': ['.png'],
            'image/svg': ['.svg'],
            'image/jpg': ['.jpg']
        }
    });

    useEffect(() => {
        if (acceptedFiles && acceptedFiles.length > 0) {
            const selectedImage = acceptedFiles[0] as File;
            onSelectImage(selectedImage);
        }
    }, [acceptedFiles, onSelectImage]);

    const acceptedFileItems = acceptedFiles.map((file: FileWithPath) => (
        <div key={file.path}>
            {file.path} - {file.size / 1000} KB
        </div>
    ));

    const fileImportedStyle = classNames({
        "container border rounded p-5": acceptedFileItems.length === 0,
        "container border rounded p-3": acceptedFileItems.length > 0
    })
    return (
        <>
            <div className={fileImportedStyle} style={{ fontSize: "0.875rem" }} {...getRootProps()}>
                <input {...getInputProps()} />

                {
                    isDragActive
                        ?
                        <div className="row">
                            <div className="col d-flex justify-content-center">Drop the files here! </div>
                        </div>
                        :
                        acceptedFileItems.length > 0
                            ?

                            <div className="row d-flex justify-content-between">
                                <div className="col fs-6 text">{acceptedFileItems}</div>
                                <div className="col d-flex justify-content-end"><FontAwesomeIcon icon={faTrashCan} size="lg" /> </div>
                            </div>
                            :
                            <div className="row">
                                <div className="row">
                                    <div className="col d-flex justify-content-center">
                                        <FontAwesomeIcon icon={faUpload} style={{ color: "#2f73e9" }} />
                                    </div>
                                </div>
                                <div className="row lh-base">
                                    <div className="col d-flex justify-content-center">
                                        <span className='text-primary' role="button" tabIndex={0}>
                                            Click to upload
                                        </span>
                                        <span className="ms-1">or drag and drop</span>
                                    </div>
                                </div>
                                <div className="row lh-sm">
                                    <div className="col d-flex justify-content-center">
                                        SVG, PNG, or JPG (max. 800x400px)
                                    </div>
                                </div>
                            </div>
                }
            </div>
        </>
    )
}