import { ReactNode } from "react";
import { documentTitle } from "../utils/helpers";
import Breadcrumbs from "./Breadcrumbs";
interface PageTemplateProps {
    title?: string | undefined;
    children: ReactNode; // represents any node in a component
    launchButton?: React.ReactNode;
    testStatus?: React.ReactNode | null;
}

export default function PageTemplate({ title, children, launchButton, testStatus }: PageTemplateProps) {
    documentTitle(title);

    return (
        <>
            <Breadcrumbs />
            <header>
                <div className="d-flex justify-content-between">
                    <div className="d-flex align-items-center">
                        <div className="page-title me-3">{title}</div>
                        <div>{testStatus}</div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center">
                        {launchButton}
                    </div>
                </div>
            </header>

            <main>
                {children}
            </main>

        </>
    );
};