
import { Configuration, PublicClientApplication, LogLevel, AccountInfo, EventType } from "@azure/msal-browser";
import appSettings from "../../appsettings.json";

// Set this here to be referenced in b2cPolicies
const authorityBaseAddress = appSettings.AzureAdB2C.Instance + "/" + appSettings.AzureAdB2C.Domain;

export const b2cPolicies = {
  names: {
    signUpSignIn: appSettings.AzureAdB2C.SignUpSignInPolicy,
  },
  authorities: {
    signUpSignIn: {
      authority: authorityBaseAddress + "/" + appSettings.AzureAdB2C.SignUpSignInPolicy,
    },
  },
  authorityDomain: appSettings.AzureAdB2C.Domain,
  authorityBaseAddress: authorityBaseAddress,
};

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig: Configuration = {
  auth: {
    clientId: appSettings.AzureAdB2C.ClientId, // This is the ONLY mandatory field that you need to supply.
    authority: b2cPolicies.authorities.signUpSignIn.authority, // Choose SUSI as your default authority.
    knownAuthorities: [b2cPolicies.authorityDomain, b2cPolicies.authorityBaseAddress], // Mark your B2C tenant's domain as trusted.
    redirectUri: appSettings.MSAL.RedirectUri, // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
    postLogoutRedirectUri: appSettings.MSAL.PostLogoutRedirectUri, // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: appSettings.MSAL.CacheLocation, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: appSettings.MSAL.StoreAuthStateInCookie, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};

/**
 * Add here the endpoints and scopes when obtaining an access token for protected web APIs. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const protectedResources = {
  getCustomers: {
    endpoint: appSettings.WebApi.BaseAddress + appSettings.WebApi.AllCustomersEndpoint || '',
    scopes: {
      read: [appSettings.WebApi.ReadScope || ''],
      run: [appSettings.WebApi.RunScope || '']
    }
  }
};  

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
  scopes: '',

};

/**
 * An optional silentRequest object can be used to achieve silent SSO
 * between applications by providing a "login_hint" property.
 */
export const silentRequest = {
  scopes: ["openid", "profile"],
};

export const initAuth = function () {
  // Default to using the first account if no account is active on page load
  if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  };

  msalInstance.addEventCallback((event) => {
    if (
      (
        event.eventType === EventType.LOGIN_SUCCESS ||
        event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
        event.eventType === EventType.SSO_SILENT_SUCCESS
      ) && event.payload
    ) {
      msalInstance.setActiveAccount(event.payload as AccountInfo);
    }
  });
}

export const getApiAccessToken = async () => {
  const activeAccount = msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
  const accounts = msalInstance.getAllAccounts();
  
  if (!activeAccount && accounts.length === 0) {
    /*
    * User is not signed in. Throw error or wait for user to login.
    * Do not attempt to log a user in outside of the context of MsalProvider
    */
    throw new Error('User is not logged in. Cannot get access token.')
  }
  const request = {
    scopes: protectedResources.getCustomers.scopes.run,
    account: activeAccount || accounts[0]
  };
  try {
    const authResult = await msalInstance.acquireTokenSilent(request);
    return authResult.accessToken
  } catch (err) {
    console.log(err);
    throw err;
  }
}

export const msalInstance = new PublicClientApplication(msalConfig);