import axiosApiInstance from "./config/axios-config"
import appSettings from "../appsettings.json"
import { AnyObject, CustomerInputs } from "../utils/data-types"
import { invariant, resolvePropertyValue } from "../utils/helpers"
import { Buffer } from 'buffer';


export const addCustomer = async ({ DisplayName, Abbreviation, DefaultBranch, CanUseMSPlaywrightService, RepoAccessKey, RepoUrl, RepoSSHUrl, RepoSSHPrivateKey }: CustomerInputs): Promise<any> => {

    // check value exists
    invariant(RepoSSHPrivateKey);
    invariant(RepoAccessKey);

    const newCustomerObject = {
        DisplayName: DisplayName,
        Abbreviation: Abbreviation,
        DefaultBranch: DefaultBranch,
        CanUseMSPlaywrightService: CanUseMSPlaywrightService,
        RepoAccessKey: RepoAccessKey,
        RepoUrl: RepoUrl,
        RepoSSHUrl: RepoSSHUrl,
        RepoSSHPrivateKey: Buffer.from(RepoSSHPrivateKey).toString('base64') // encode to base64
    };

    const response = await axiosApiInstance.post(`${appSettings.WebApi.BaseAddress}${appSettings.WebApi.AllCustomersEndpoint}`,
        newCustomerObject,
        {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    return response.data
}

export const getCustomerById = async (customerId: string): Promise<any> => {
    const response = await axiosApiInstance.get(`${appSettings.WebApi.BaseAddress}${appSettings.WebApi.AllCustomersEndpoint}/${customerId}`)
    return response.data

}

export const updateCustomerById = async ({ DisplayName, Abbreviation, DefaultBranch, CanUseMSPlaywrightService, RepoAccessKey, RepoUrl, RepoSSHUrl, RepoSSHPrivateKey, Id }: CustomerInputs): Promise<any> => {

    let customerByIdObject: AnyObject = {};
    
    customerByIdObject = resolvePropertyValue(customerByIdObject, "DisplayName", DisplayName);
    customerByIdObject = resolvePropertyValue(customerByIdObject, "Abbreviation", Abbreviation);
    customerByIdObject = resolvePropertyValue(customerByIdObject, "DefaultBranch", DefaultBranch);
    customerByIdObject = resolvePropertyValue(customerByIdObject, "CanUseMSPlaywrightService", CanUseMSPlaywrightService);
    customerByIdObject = resolvePropertyValue(customerByIdObject, "RepoUrl", RepoUrl);
    customerByIdObject = resolvePropertyValue(customerByIdObject, "RepoSSHUrl", RepoSSHUrl);
    customerByIdObject = resolvePropertyValue(customerByIdObject, "RepoAccessKey", RepoAccessKey);
    customerByIdObject = resolvePropertyValue(customerByIdObject, "RepoSSHPrivateKey", RepoSSHPrivateKey ? Buffer.from(RepoSSHPrivateKey).toString('base64'): null) // encode to base64);

    const response = await axiosApiInstance.put(`${appSettings.WebApi.BaseAddress}${appSettings.WebApi.AllCustomersEndpoint}/${Id}`,
        customerByIdObject,
        {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    return response.data
}

export const deleteCustomerById = async (customerId: string): Promise<any> => {
    const response = await axiosApiInstance.delete(`${appSettings.WebApi.BaseAddress}${appSettings.WebApi.AllCustomersEndpoint}/${customerId}`)
    return response
  }