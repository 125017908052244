import { useQuery } from "@tanstack/react-query";
import { getCustomers } from "../../api/customers";
import { QueryResponse } from "../../utils/data-types";
import CustomerCard from "./CustomerCard";
import { ThreeDots } from 'react-loader-spinner';
import DataCounter from "../../components/DataCounter";

interface Customers {
    Id: string;
    DisplayName: string;
    LogoUrl: string;
}

export default function CustomerList() {
    // Get all customers with 'view_results' permissions
    const { isLoading, data: customersData, isError, error }: QueryResponse = useQuery({
        queryKey: ["customers"],
        queryFn: () => getCustomers("view_results")
    });

    if (isLoading) {
        return <ThreeDots wrapperClass="spinner-wrapper" color="#283342" height="80"
            width="80" />
    }

    if (!customersData) {
        return <p className="text-danger">No data found</p>
    }

    if (isError) {
        console.log("Error", error)
        return <h4 className="text-danger">Error: {error?.message}</h4>
    }

    return (
        <>
            <DataCounter
                dataCount={customersData.Customers.length}
                typeOfData="customers"
            />

            <div className="row row-cols-1 row-cols-md-4 g-4">
                <>
                    {customersData.Customers.map(({ Id, DisplayName, LogoUrl }: Customers) => (
                        <CustomerCard
                            key={Id}
                            id={Id}
                            customer={DisplayName}
                            logoUrl={LogoUrl}
                        />
                    ))}
                </>
            </div>
        </>
    );
}