import { useEffect, useState } from 'react';
import { ThreeDots } from "react-loader-spinner";
import { getCustomers } from "../../api/customers";
import { userTableProps } from "../../utils/data-types";
import AssignCustomersOffCanvas from "./AssignCustomersOffCanvas";
import { POSSIBLEROLESARRAY } from "./utils/constants";

interface AssignCanvasProps {
    onHideModal: () => void;
    showModal?: boolean;
    assignCustomerProps?: userTableProps | null;
}

export default function AssignCustomerData(props: AssignCanvasProps) {
    const { assignCustomerProps, onHideModal, showModal } = props;

    const [userMgmtData, setUserMgmtData] = useState<any>(null);
    const [isLoading, setIsLoading] = useState(false);

    // Fetch user management data and set states.
    // Using a useEffect instead of RQ because this scenario doesn't require the
    // advantages provided by RQ like Caching, BG Fetching, Real-time Data Reactivity etc..
    // We just need fresh data when assignCustomerProps updates. 
    useEffect(function fetchMngtData() {
        const loadUserMngtData = async () => {
            try {
                setIsLoading(true)
                const response = await getCustomers("user_mgmt");
                setUserMgmtData(response);
                setIsLoading(false)
            } catch (error) {
                console.error("Error:", error);
            }
        };
        loadUserMngtData()

    }, [assignCustomerProps])




    // The position of the values in POSSIBLEROLESARRAY is important. They must be
    // the same throughout the life of the component. Ensure the positions of values 
    // in this array align with the default order expected by the checkbox component or 
    // checkbox behavior and values will be wrong.

    // Compare inputArray with values in (POSSIBLEROLESARRAY), If a value is missing, set it to null in that position.
    const mapValuesToPositions = (inputArray: Array<string>, values: Array<string>): Array<string | null> => {
        const resultArray: Array<string | null> = new Array(values.length).fill(null);

        inputArray.forEach((value) => {
            const index = values.indexOf(value);
            if (index !== -1) {
                resultArray[index] = value;
            }
        });

        return resultArray;
    };

    // Merges logged in users manageable customers array with the clicked on user from the user table
    // (passed prop from userTable). Returns an object with all roles and its values to be 
    // set as checkbox default values. This method retains previous unchanged values and updated 
    // values in one object.  
    const checkboxDefaultRoles = (userTable: userTableProps | null | undefined, customers: []) => {
        const updateDefaultcheckboxValues: { [key: string]: any } = {};

        if (userTable === null || userTable === undefined) {
            return updateDefaultcheckboxValues
        }

        customers?.forEach((customer: { Abbreviation: string }) => {
            if (userTable.CustomerRoles.hasOwnProperty(customer.Abbreviation)) {

                updateDefaultcheckboxValues[customer.Abbreviation] =
                    mapValuesToPositions(userTable.CustomerRoles[customer.Abbreviation].Roles, POSSIBLEROLESARRAY)

            } else {
                // Add possible customer with empty Roles array
                updateDefaultcheckboxValues[customer.Abbreviation] = []
            }
        });

        return updateDefaultcheckboxValues
    };

    // Loading User Management Data
    if (isLoading) {
        return <ThreeDots wrapperClass="spinner-wrapper" color="#283342" height="80"
            width="80" />
    }

    // Assigned roles Default values per users available customers
    const checkboxCustomerValues = checkboxDefaultRoles(assignCustomerProps, userMgmtData?.Customers);

    return (
        <>
            {checkboxCustomerValues !== undefined && assignCustomerProps !== undefined
                ?
                <AssignCustomersOffCanvas
                    assignCustomerRoles={checkboxCustomerValues}
                    assignCustomerProps={assignCustomerProps}
                    customerMngtData={userMgmtData?.Customers}
                    showModal={showModal}
                    onHideModal={onHideModal}
                />
                :
                null
            }

        </ >
    );
}

