import { useState } from "react";
import ToggleableButton from "../../components/ToggleableButton";
import CustomerList from "./CustomerList";
import PageTemplate from "../../components/PageTemplate";
import CreateCustomerModal from "./CreateCustomerModal";
import { useCheckPermissions } from "../../hooks/useCheckPermissions";
import { CustomerObject } from "../../utils/data-types";

export default function Customers() {
    const [customerModalShow, setCustomerModalShow] = useState<boolean>(false);
    const { data: userPermissionData } = useCheckPermissions();

    const customersAppProps: CustomerObject =
        userPermissionData && userPermissionData.SuperUserPermissions
            ? userPermissionData.SuperUserPermissions
            : []

    // Check if passed values is in any of the Super User Permissions array
    // Only super user can create a customer
    let canCreateCustomer = false;
    if (customersAppProps.includes("customer_mgmt")) {
        canCreateCustomer = true;
    }

    const createCustomer =
        <ToggleableButton buttonGroup={false} className={"btn btn-primary"} title={"Create Customer"} onClick={() => setCustomerModalShow(true)} />

    return (
        <>
            <CreateCustomerModal
                show={customerModalShow}
                onHide={() => { setCustomerModalShow(false) }}
            />

            <PageTemplate title="Customers" launchButton={canCreateCustomer ? createCustomer : null}>
                <CustomerList />
            </PageTemplate>
        </>
    );
}