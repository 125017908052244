import axiosApiInstance from "./config/axios-config"
import appSettings from "../appsettings.json"
import { InviteUser, updateUserProps } from "../utils/data-types"
import { TrimAndCapitalize } from "../utils/helpers"

export const getUsers = async (): Promise<any> => {
    const response = await axiosApiInstance.get(`${appSettings.WebApi.BaseAddress}${appSettings.WebApi.AllUsersEndpoint}`)
    return response.data

}

export const inviteUser = async ({ FirstName, LastName, Email }: InviteUser): Promise<any> => {
    const createUserInfo = {
        FirstName: TrimAndCapitalize(FirstName),
        LastName: TrimAndCapitalize(LastName),
        Email: Email
    }

    const response = await axiosApiInstance.post(`${appSettings.WebApi.BaseAddress}${appSettings.WebApi.AllUsersEndpoint}`,
        createUserInfo,
        {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    return response.data

}

export const deleteUser = async (userId: string): Promise<any> => {
    const response = await axiosApiInstance.delete(`${appSettings.WebApi.BaseAddress}${appSettings.WebApi.AllUsersEndpoint}/${userId}`)
    return response.data

}

export const updateUser = async ({ userId, roleAssignmentData }: updateUserProps): Promise<any> => {
    const updateUserObject = {
        "RoleAssignments": roleAssignmentData
    }

    const response = await axiosApiInstance.put(`${appSettings.WebApi.BaseAddress}${appSettings.WebApi.AllUsersEndpoint}/${userId}`,
        updateUserObject,
        {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    return response

}